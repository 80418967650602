var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"text-18 text-gray-700 font-bold mb-5 mt-10"},[_vm._v("Notifications")]),_c('div',[_c('div',{staticClass:"flex mb-10"},[_c('div',{staticClass:"duration-300 w-full"},[(_vm.hasUnreadNotifications)?_c('h2',{staticClass:"text-12 font-semibold text-ash-800 mb-10 mr-3"},[_vm._v(" Unread ")]):_vm._e(),_vm._l((_vm.notifications),function(noty,index){return _c('div',{key:`unread-${index}`,staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex flex-row mb-1 bg-credpal-900 h-full pt-5 pb-3 rounded w-full md:w-550px cursor-pointer",on:{"click":function($event){return _vm.displayNotification(noty)}}},[_c('div',{staticClass:"h-4 w-3px mt-4 rounded-full bg-green-500"}),_c('div',{staticClass:"h-40px w-40px ml-6 p-2 rounded-full text-xl text-center text-white font-semibold bg-green-500"},[_vm._v(" "+_vm._s(noty.title.trim().slice(0, 1))+" ")]),_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex flex-col w-48 md:w-250px ml-5 mt-1"},[_c('h4',{staticClass:"text-12 lg:text-13 text-gray-800 font-semibold cursor-pointer",on:{"click":function($event){return _vm.displayNotification(noty)}}},[_vm._v(" "+_vm._s(noty.title)+" ")]),_c('p',{staticClass:"text-10 lg:text-12 mt-1 text-gray-800 font-thin",domProps:{"innerHTML":_vm._s(
          noty.data
           .split(' ')
           .slice(0, 7)
           .join(' ')
         )}},[_vm._v(" There was an error in your verificati... ")])]),_c('h4',{staticClass:"text-10 mt-1 lg:text-12 text-gray-800 text-right lg:ml-32 font-normal"},[_vm._v(" "+_vm._s(_vm.momentDate(noty.created_at))+" ")])])])])}),(_vm.hasReadNotifications)?_c('h2',{staticClass:"text-12 font-semibold text-left mt-10 text-ash-800 mb-10 mr-3"},[_vm._v(" Read ")]):_vm._e(),_vm._l((_vm.getReadNotifications),function(read,index){return _c('div',{key:`read-${index}`,staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex flex-row mb-1 bg-credpal-900 h-full pt-5 pb-3 rounded w-full md:w-550px cursor-pointer",on:{"click":function($event){return _vm.markAsRead(read)}}},[_c('div',{staticClass:"h-40px w-40px ml-6 p-2 rounded-full text-xl text-center text-white font-semibold bg-ash-800"},[_vm._v(" "+_vm._s(read.title.trim().slice(0, 1))+" ")]),_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex flex-col w-48 md:w-250px ml-5 mt-1"},[_c('h4',{staticClass:"text-13 text-gray-800 font-semibold cursor-pointer",on:{"click":function($event){return _vm.markAsRead(read)}}},[_vm._v(" "+_vm._s(read.title)+" ")]),_c('p',{staticClass:"text-10 lg:text-12 mt-1 text-gray-800 font-thin",domProps:{"innerHTML":_vm._s(
          read.data
           .split(' ')
           .slice(0, 7)
           .join(' ')
         )}})]),_c('h4',{staticClass:"text-10 lg:text-12 mt-1 text-gray-800 text-right lg:ml-32 font-normal"},[_vm._v(" "+_vm._s(_vm.momentDate(read.read_at))+" ")])])])])})],2)]),_c('modal',{ref:"notyModal",attrs:{"className":"w-11/12 md:w-443px flex flex-col rounded"}},[_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"h-50px w-50px p-3 pt-4 rounded text-xl text-center text-white font-semibold",class:_vm.notification.read_at ? 'bg-ash-800' : 'bg-purple-500'},[_vm._v(" "+_vm._s(_vm.notification.title ? _vm.notification.title.trim().slice(0, 1) : "")+" ")]),_c('div',{staticClass:"flex flex-col ml-5 mt-1"},[_c('h4',{staticClass:"text-18 text-gray-700 font-semibold"},[_vm._v(" "+_vm._s(_vm.notification.title)+" ")]),(_vm.notification.read_at)?_c('p',{staticClass:"text-12 mt-1 text-ash-800 font-thin"},[_vm._v(" Read At "+_vm._s(_vm.momentDate(_vm.notification.created_at, "daysAgo"))+" ")]):_c('p',{staticClass:"text-12 mt-1 text-ash-800 font-thin"},[_vm._v(" "+_vm._s(_vm.momentDate(_vm.notification.created_at, "daysAgo"))+" ")])])]),_c('div',{staticClass:"mt-10"},[_c('p',{staticClass:"text-13 text-gray-800 font-thin tracking-wide leading-loose text-left",domProps:{"innerHTML":_vm._s(_vm.notification.data)}})])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }